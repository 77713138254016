<template>
    <div>
        <div class="main-container">
            <KidHeader :profileId="profileId" :serialNumber="serialNumber" />
            <div class="profile-section" style="border-bottom: unset;">
                <div>
                    <span class="pl-3 header-font"><b>Apps</b></span>
                    <div class="float-right pr-3">
                        <span v-if="deviceDetail && deviceDetail.profile_id">{{ deviceDetail.profile_id.profile_name }}</span><br>
                        <small class="device-name" v-if="deviceDetail">{{ deviceDetail.hardwareInfo.brand }} {{ deviceDetail.hardwareInfo.model }}</small>
                    </div>
                </div>
            </div>
            <div class="profile-section" style="border-bottom: unset;">
                <div class="pl-3 pr-3">
                    <div class="input-group mb-3 has-search">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" class="form-control" @keyup.enter="getPlayStore('Terms')" placeholder="Search" v-model="playstoreText">
                        <!--<div class="input-group-prepend">
                            <button class="btn btn-outline-secondary" :disabled="isDisabled" @click="getPlayStore('Terms')"><i class="fas fa-search"></i> <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></button>
                        </div>-->
                    </div>
                </div>
            </div>
            <div class="profile-section ScrollStyle" style="border-bottom: unset;white-space:nowrap;">
                <div class="pl-3 pr-3" :style="{ display: 'block ruby' }">
                    <!--<button type="button" class="btn btn-secondary mr-2" style="background-color: #F2F2F2; color: #333333;" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                      <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="24" height="24" rx="5" fill="#F2F2F2"/>
                        <g clip-path="url(#clip0_4_1125)">
                        <path d="M17.0063 19.9546H23.07" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8.8335 19.9546H11.7335" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0061 19.9546C17.0061 20.476 16.8515 20.9857 16.5618 21.4193C16.2721 21.8528 15.8604 22.1907 15.3787 22.3903C14.8969 22.5898 14.3668 22.642 13.8554 22.5403C13.344 22.4386 12.8743 22.1875 12.5056 21.8188C12.1369 21.4501 11.8858 20.9803 11.7841 20.4689C11.6823 19.9575 11.7345 19.4274 11.9341 18.9457C12.1336 18.464 12.4715 18.0522 12.9051 17.7625C13.3386 17.4729 13.8483 17.3182 14.3698 17.3182C15.069 17.3182 15.7395 17.596 16.234 18.0904C16.7284 18.5848 17.0061 19.2554 17.0061 19.9546Z" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18.0608 12.0455H8.8335" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.3333 12.0455C23.3333 11.5241 23.1787 11.0143 22.889 10.5808C22.5993 10.1473 22.1875 9.80934 21.7058 9.6098C21.2241 9.41026 20.694 9.35805 20.1826 9.45978C19.6712 9.5615 19.2014 9.81259 18.8327 10.1813C18.464 10.55 18.2129 11.0198 18.1112 11.5312C18.0095 12.0426 18.0617 12.5726 18.2612 13.0544C18.4608 13.5361 18.7987 13.9479 19.2322 14.2375C19.6658 14.5272 20.1755 14.6818 20.6969 14.6818C21.3961 14.6818 22.0667 14.4041 22.5611 13.9097C23.0555 13.4153 23.3333 12.7447 23.3333 12.0455Z" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_4_1125">
                        <rect width="16" height="16" fill="white" transform="translate(8 8)"/>
                        </clipPath>
                        </defs>
                        </svg>
                    </button>-->
                    <button type="button" class="btn btn-secondary mr-2" :class="{ 'focus': ageFilter }" style="background-color: #F2F2F2; color: #333333;padding:0.250rem .75rem;border:unset;" data-toggle="collapse" data-target="#sortByPopup" aria-controls="sortByPopup" aria-expanded="false" aria-label="Toggle navigation">Age Group
                      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.33317 3.33331L5.15687 6.59967C5.07019 6.68897 4.92948 6.68897 4.8428 6.59967L1.6665 3.33331" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </button>
                    <button type="button" class="btn btn-secondary mr-2" :class="{ 'focus': filterCategory }" style="background-color: #F2F2F2; color: #333333;padding:0.250rem .75rem;border:unset;" data-toggle="collapse" data-target="#filterByCategoryPopup" aria-controls="filterByCategoryPopup" aria-expanded="false" aria-label="Toggle navigation">Category 
                      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.33317 3.33331L5.15687 6.59967C5.07019 6.68897 4.92948 6.68897 4.8428 6.59967L1.6665 3.33331" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </button>
                    <button type="button" class="btn btn-secondary mr-2" :class="{ 'focus': expertRatingFilter }" style="background-color: #F2F2F2; color: #333333;padding:0.250rem .75rem;border:unset;" data-toggle="collapse" data-target="#expertRatingPopup" aria-controls="sortByPopup" aria-expanded="false" aria-label="Toggle navigation">Expert Rating
                      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.33317 3.33331L5.15687 6.59967C5.07019 6.68897 4.92948 6.68897 4.8428 6.59967L1.6665 3.33331" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </button>
                </div>
                <div class="collapse m-2 footer" id="sortByPopup">
                    <div class="card">
                        <div class="card-body">
                            <p class="text-muted"><b>Choose Age</b> <a href="javascript:void(0)" class="text-black" @click="closePopUp()"><i class="fas fa-times float-right pt-1 pr-2"></i></a></p>
                            <div class="m-2">
                                <div class="row p-2">
                                    <div class="col-xs-3 pr-2">
                                        <div class="btn-group-toggle" data-toggle="buttons">
                                            <label class="btn btn-secondary-custom" :class="{ 'active': ageFilter == 'AGE_RANGE1' }">
                                              <input type="radio"  v-model="ageFilter" :checked="ageFilter == 'AGE_RANGE1'"  value="AGE_RANGE1" >Under 5
                                            </label>
                                          </div>
                                    </div>
                                    <div class="col-xs-3 pr-2">
                                        <div class="btn-group-toggle" data-toggle="buttons">
                                            <label class="btn btn-secondary-custom" :class="{ 'active': ageFilter == 'AGE_RANGE2' }">
                                              <input type="radio" v-model="ageFilter" :checked="ageFilter == 'AGE_RANGE2'" value="AGE_RANGE2" >6-8
                                            </label>
                                          </div>
                                    </div>
                                    <div class="col-xs-3 pr-2">
                                        <div class="btn-group-toggle" data-toggle="buttons">
                                            <label class="btn btn-secondary-custom" :class="{ 'active': ageFilter == 'AGE_RANGE3' }">
                                              <input type="radio" v-model="ageFilter" :checked="ageFilter == 'AGE_RANGE3'" value="AGE_RANGE3" >9 Up
                                            </label>
                                          </div>
                                    </div>
                                </div>
                                <div class="row p-2">
                                  <div class="col-xs-3 pr-2">
                                        <div class="btn-group-toggle" data-toggle="buttons">
                                            <label class="btn btn-secondary-custom" :class="{ 'active': ageFilter == 'AGE_RANGE_ALL' }">
                                              <input type="radio" v-model="ageFilter" :checked="ageFilter == 'AGE_RANGE_ALL'" value="AGE_RANGE_ALL" >All ages up to 12
                                            </label>
                                          </div>
                                  </div>
                                </div>
                                <div class="border-top pt-3">
                                    <a href="javascript:void(0)" @click="clearAgeFilter()">Clear</a>
                                    <button type="button" class="btn btn-primary float-right" @click="getPlayStore('Age')">Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="collapse m-2 footer" id="filterByCategoryPopup">
                    <div class="card">
                        <div class="card-body">
                            <p class="text-muted"><b>Choose Categories</b> <a href="javascript:void(0)" class="text-black" @click="closePopUp()"><i class="fas fa-times float-right pt-1 pr-2"></i></a></p>
                            <div class="m-2" :style="{ 'overflow-y': 'auto', 'height': '450px' }">
                                <table class="table">
                                  <tbody>
                                    <tr v-for="parentCat in playStoreCategories" :key="parentCat">
                                      <td v-for="category in parentCat" :key="category" :style="{'border-top': 'unset', 'padding': '0.25rem'}">
                                        <div class="btn-group-toggle" data-toggle="buttons">
                                            <label class="btn btn-secondary-custom" :class="{ 'active': filterCategory == category }" :style="{ 'font-size': '12px', 'text-transform': 'lowercase', 'width': '100%' }">
                                              <input type="radio"  v-model="filterCategory" :value="category" >
                                              {{ category.replace(/_/g, ' ') }}
                                            </label>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <!--<div class="row p-2" v-for="parentCat in playStoreCategories" :key="parentCat">
                                    <div class="col-xs-6 pr-2" v-for="category in parentCat" :key="category">
                                        <div class="btn-group-toggle ml-4" data-toggle="buttons">
                                            <label class="btn btn-secondary-custom" :style="{ 'font-size': '12px', 'text-transform': 'lowercase' }">
                                              <input type="checkbox" name="filterCategory" v-model="filterCategory" :true-value="category" false-value="" >
                                              {{ category.replace(/_/g, ' ') }}
                                            </label>
                                        </div>
                                    </div>
                                </div>-->
                            </div>
                            <div class="border-top pt-3">
                                <a href="javascript:void(0)" @click="clearCategoryFilter()">Clear</a>
                                <button type="button" class="btn btn-primary float-right" @click="getPlayStore('Category')">Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="collapse m-2 footer" id="expertRatingPopup">
                    <div class="card">
                        <div class="card-body">
                            <p class="text-muted"><b>Choose Expert Rating</b> <a href="javascript:void(0)" class="text-black" @click="closePopUp()"><i class="fas fa-times float-right pt-1 pr-2"></i></a></p>
                            <div class="m-2">
                                <div class="row p-2">
                                  <div class="col-xs-3 pr-2">
                                      <div class="btn-group-toggle" data-toggle="buttons">
                                        <label class="btn btn-secondary-custom label-custom-filter font-14" :class="{ 'active': expertRatingFilter == 'EXPERT_RECOMMENDATION' }">
                                          <input type="radio" v-model="expertRatingFilter" :checked="expertRatingFilter == 'EXPERT_RECOMMENDATION'" value="EXPERT_RECOMMENDATION" >
                                           Recommended &nbsp;
                                        </label>
                                      </div>
                                  </div>
                                </div>
                                <div class="row p-2">
                                  <div class="col-xs-3 pr-2">
                                      <div class="btn-group-toggle" data-toggle="buttons">
                                        <label class="btn btn-secondary-custom label-custom-filter font-14" :class="{ 'active': expertRatingFilter == 'PARENTAL_SUPERVISION' }">
                                          <input type="radio" v-model="expertRatingFilter" :checked="expertRatingFilter == 'PARENTAL_SUPERVISION'" value="PARENTAL_SUPERVISION" >
                                          Recommended with parental supervision &nbsp;
                                        </label>
                                      </div>
                                  </div>
                                </div>
                                <!--<div class="row p-2">
                                  <div class="col-xs-3 pr-2">
                                      <div class="btn-group-toggle" data-toggle="buttons">
                                        <label class="btn btn-secondary-custom label-custom-filter font-14" :class="{ 'active': expertRatingFilter == 'NOT_RATED' }">
                                          <input type="radio" v-model="expertRatingFilter" :checked="expertRatingFilter == 'NOT_RATED'" value="NOT_RATED" >
                                          &nbsp;
                                          <img :src="notRated" height="auto" width="60px"/> Not Certified Yet&nbsp;
                                        </label>
                                      </div>
                                  </div>
                                </div>-->
                                <div class="border-top pt-3">
                                    <a href="javascript:void(0)" @click="clearExpertRatingFilter()">Clear</a>
                                    <button type="button" class="btn btn-primary float-right" @click="getPlayStore('ExpertRating')">Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="profile-section" style="border-bottom: unset;">
                <div class="pl-3 pr-3">
                    <div class="nav nav-pills" role="tablist">
                      <a class="nav-link active" href="#installedApps" role="tab" data-toggle="tab"><b>Installed</b></a>
                        <a class="nav-link" href="#playstore" role="tab" data-toggle="tab" id="all"><b>Search Result</b></a>
                    </div>
                    <hr :style="{ margin: 'unset' }">
                    <div class="tab-content p-2">
                        <div class="tab-pane" id="playstore">
                            <h5 class="pb-2" :style="{ 'font-size': '18px' }"><b>{{ playstoreLabel }}</b></h5>
                            <div class="row justify-content-center" v-if="showLoader == true">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <div class="row border-bottom app-list" v-for="application in playStoreApplications" :key="application._id">
                                <div class="col-3 align-items-center pt-1" v-if="!hiddenPackages.includes(application.appId)">
                                    <a href="javascript:void(0)" class="text-app-list" @click="$router.push({ path: '/application', query: { package_name: application.appId, profile_id: this.profileId, serialNumber: this.serialNumber, playstoreText: this.playstoreText, filterCategory: this.filterCategory, ageFilter: this.ageFilter, expertRatingFilter: this.expertRatingFilter } })">
                                      <img :src="application.icon" style="height: 70px; width: 70px;;border-radius: 15px;">
                                    </a>
                                </div>
                                <div class="col-9 pl-2 align-items-center" v-if="!hiddenPackages.includes(application.appId)">
                                    <span><a href="javascript:void(0)" class="text-app-list" @click="$router.push({ path: '/application', query: { package_name: application.appId, profile_id: this.profileId, serialNumber: this.serialNumber, playstoreText: this.playstoreText, filterCategory: this.filterCategory, ageFilter: this.ageFilter, expertRatingFilter: this.expertRatingFilter } })">{{ application.title }}</a></span><br>
                                    <div>
                                        <label v-if="application.expertRating == 'EXPERT_RECOMMENDATION'">
                                          <img :src="exportGreen" height="auto" width="17px"/>
                                        </label>
                                        <label v-if="application.expertRating == 'PARENTAL_SUPERVISION'">
                                          <img :src="exportOrange" height="auto" width="17px"/>
                                        </label>
                                        <label v-if="application.expertRating == '16+'">
                                          <img :src="sixteenPlus" height="auto" width="17px"/>
                                        </label>
                                        <label v-if="application.expertRating == ''">
                                          <img :src="notRated" height="auto" width="17px"/>
                                        </label>
                                        <span class="pl-4"><i class="fas fa-star" style="color: gold;"></i> {{ application.scoreText }} </span>
                                        <span class="pl-4">
                                          <img :style="{'margin-bottom':'2px'}" v-if="application.kidsRating.avgKidsRating >= 3" :src="childPositiveSmile" />
                                          <img class="mb-1" v-else-if="application.kidsRating.avgKidsRating >= 2 && application.kidsRating.avgKidsRating < 3" :src="childNeutralSmile" />
                                          <img :style="{'margin-bottom':'2px'}" v-else-if="application.kidsRating.avgKidsRating >= 1 && application.kidsRating.avgKidsRating < 2" :src="childNegativeSmile" />
                                          <i v-else class="far fa-smile rating-grey" />
                                          {{ application.overallRating }}
                                        </span>
                                        <span class="pl-4" v-if="application.price > 0">₣ {{ application.price }}</span>
                                    </div>
                                    <div>
                                        <button type="button" class="float-right mb-2 install-app-btn" v-if="application.free == false && application.isAppInstalled == false" >Coming Soon</button>
                                        <div v-else>
                                          <button type="button" :disabled="isDisabled" class="float-right mb-2 install-app-btn" v-if="application.isAppInstalled == false" @click="createProfileApplication(application)">Install</button>
                                          <a :disabled="isDisabled" class="float-right mb-2" v-else @click="uninstallProfileApplication(application.appId)" href="javascript:void(0)">
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <rect width="32" height="32" rx="16" fill="#F2F2F2"/>
                                              <g clip-path="url(#clip0_4_1201)">
                                              <path d="M20.308 23.3849H11.6921C11.5305 23.3849 11.3705 23.3531 11.2212 23.2912C11.0719 23.2294 10.9362 23.1387 10.822 23.0244C10.7077 22.9101 10.6171 22.7743 10.5554 22.625C10.4936 22.4757 10.4618 22.3156 10.4619 22.154V11.077H21.5382V22.154C21.5382 22.4803 21.4086 22.7932 21.1779 23.0239C20.9471 23.2546 20.6342 23.3849 20.308 23.3849Z" fill="#FCD9E2" stroke="#ED0040" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                              <path d="M14.1538 19.6921V14.7698" stroke="#ED0040" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                              <path d="M17.8462 19.6921V14.7698" stroke="#ED0040" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                              <path d="M8 11.077H24" stroke="#ED0040" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                              <path d="M17.8466 8.61511H14.1545C13.9929 8.61511 13.8329 8.64696 13.6836 8.70883C13.5343 8.77069 13.3986 8.86138 13.2844 8.97569C13.1701 9.09 13.0795 9.2257 13.0178 9.37504C12.956 9.52438 12.9242 9.68443 12.9243 9.84605V11.0763H19.0776V9.84605C19.0776 9.51977 18.9479 9.20686 18.7172 8.97615C18.4865 8.74544 18.1736 8.61582 17.8473 8.61582L17.8466 8.61511Z" fill="#FCD9E2" stroke="#ED0040" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                              </g>
                                              <defs>
                                              <clipPath id="clip0_4_1201">
                                              <rect width="18" height="18" fill="white" transform="translate(7 7)"/>
                                              </clipPath>
                                              </defs>
                                              </svg>
                                              <i v-if="isDisabled" class="fa fa-spinner fa-spin"></i>
                                          </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane active" id="installedApps">
                            <div class="row border-bottom app-list" v-for="application in installedApplicationList" :key="application._id">
                                <div class="col-3 align-items-center pt-1">
                                    <a href="javascript:void(0)" class="text-app-list" @click="$router.push({ path: '/application', query: { package_name: application.package_name, profile_id: this.profileId, serialNumber: this.serialNumber, playstoreText: this.playstoreText, filterCategory: this.filterCategory, ageFilter: this.ageFilter, expertRatingFilter: this.expertRatingFilter } })">
                                      <img :src="application.app_icon" style="height: 70px; width: 70px;border-radius: 15px;">
                                    </a>
                                </div>
                                <div class="col-9 pl-2 align-items-center">
                                    <span>
                                      <a href="javascript:void(0)" class="text-app-list" @click="$router.push({ path: '/application', query: { package_name: application.package_name, profile_id: this.profileId, serialNumber: this.serialNumber, playstoreText: this.playstoreText, filterCategory: this.filterCategory, ageFilter: this.ageFilter, expertRatingFilter: this.expertRatingFilter } })">{{ application.display_text }}</a>
                                      <a :style="{ 'text-decoration': 'unset' }" v-if="application.installationFailureReason" href="javascript:void(0)" @click="openAppInstallationErrorPopup(application.installationFailureReason)">&nbsp; <i class="fas fa-exclamation-circle text-danger"></i></a>
                                    </span><br>
                                    <div>
                                        <label v-if="application.expertRating == 'EXPERT_RECOMMENDATION'">
                                          <img :src="exportGreen" height="auto" width="17px"/>
                                        </label>
                                        <label v-if="application.expertRating == 'PARENTAL_SUPERVISION'">
                                          <img :src="exportOrange" height="auto" width="17px"/>
                                        </label>
                                        <label v-if="application.expertRating == '16+'">
                                          <img :src="sixteenPlus" height="auto" width="17px"/>
                                        </label>
                                        <label v-if="application.expertRating == ''">
                                          <img :src="notRated" height="auto" width="17px"/>
                                        </label>
                                        <span class="pl-4" v-if="application.playstoreDetail"><i class="fas fa-star" style="color: gold;"></i> 
                                          {{ application.playstoreDetail.scoreText }}
                                        </span>
                                        <span class="pl-4" v-else><i class="fas fa-star" style="color: gold;"></i> 0.0</span>
                                        <span class="pl-4">
                                          <img :style="{'margin-bottom':'3px'}" v-if="application.kidsRating.avgKidsRating >= 3" :src="childPositiveSmile" />
                                          <img class="mb-1" v-else-if="application.kidsRating.avgKidsRating >= 2 && application.kidsRating.avgKidsRating < 3" :src="childNeutralSmile" />
                                          <img :style="{'margin-bottom':'2px'}" v-else-if="application.kidsRating.avgKidsRating >= 1 && application.kidsRating.avgKidsRating < 2" :src="childNegativeSmile" />
                                          <i v-else class="far fa-smile rating-grey" />
                                          {{ application.overallRating }}
                                        </span>
                                    </div>
                                    <div>
                                        <a v-if="application.package_name != 'com.nova.kidsfirstphone'" class="float-right mb-2" @click="uninstallProfileApplication(application.package_name)" href="javascript:void(0)">
                                          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="32" height="32" rx="16" fill="#F2F2F2"/>
                                            <g clip-path="url(#clip0_4_1201)">
                                            <path d="M20.308 23.3849H11.6921C11.5305 23.3849 11.3705 23.3531 11.2212 23.2912C11.0719 23.2294 10.9362 23.1387 10.822 23.0244C10.7077 22.9101 10.6171 22.7743 10.5554 22.625C10.4936 22.4757 10.4618 22.3156 10.4619 22.154V11.077H21.5382V22.154C21.5382 22.4803 21.4086 22.7932 21.1779 23.0239C20.9471 23.2546 20.6342 23.3849 20.308 23.3849Z" fill="#FCD9E2" stroke="#ED0040" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M14.1538 19.6921V14.7698" stroke="#ED0040" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M17.8462 19.6921V14.7698" stroke="#ED0040" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M8 11.077H24" stroke="#ED0040" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M17.8466 8.61511H14.1545C13.9929 8.61511 13.8329 8.64696 13.6836 8.70883C13.5343 8.77069 13.3986 8.86138 13.2844 8.97569C13.1701 9.09 13.0795 9.2257 13.0178 9.37504C12.956 9.52438 12.9242 9.68443 12.9243 9.84605V11.0763H19.0776V9.84605C19.0776 9.51977 18.9479 9.20686 18.7172 8.97615C18.4865 8.74544 18.1736 8.61582 17.8473 8.61582L17.8466 8.61511Z" fill="#FCD9E2" stroke="#ED0040" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_4_1201">
                                            <rect width="18" height="18" fill="white" transform="translate(7 7)"/>
                                            </clipPath>
                                            </defs>
                                            </svg>
                                            <i v-if="isDisabled" class="fa fa-spinner fa-spin"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="collapse m-2 footer" id="appInstallationFailurPopup">
                <div class="card">
                    <div class="card-body">
                        <h6 class="text-black">
                            <b>App Installation Status</b> <a href="javascript:void(0)" class="text-black" @click="closeAppInstallationErrorPopup()"><i class="fas fa-times float-right pt-1 pr-2"></i></a></h6>
                        <hr>
                        <p>{{ appInstallaionErrors[installationFailureReason] }}</p>
                    </div>
                </div>
            </div>
            <div class="profile-section text-center pt-2">
                  Last Update: {{ lastUpdated }}
            </div>
            <KidFooter />
        </div>
    </div>
</template>
<script>
import KidHeader from '@/components/KidHeader.vue'
import ProfileService from "../../services/ProfileService";
import KidsService from "../../services/KidsService";
import KidFooter from '@/components/KidFooter.vue'
let options = { weekday: 'short', month: 'long', day: 'numeric' };

export default {
  name: 'Play Store',
  components: {
    KidHeader,
    KidFooter
  },
  props: {
    notRated: {
        type: String,
        default: "./images/not-certified.png",
    },
    exportOrange: {
        type: String,
        default: "./images/experts-orange.png",
    },
    exportGreen: {
        type: String,
        default: "./images/experts-green.png",
    },
    sixteenPlus: {
        type: String,
        default: "./images/16-plus.png",
    },
    childPositiveSmile: {
        type: String,
        default: "./images/child-positive-smile.png",
    },
    childNeutralSmile:{
        type: String,
        default: "./images/child-neutral-smile.png",
    },
    childNegativeSmile:{
        type: String,
        default: "./images/child-negative-smile.png",
    },
  },
  data() {
    return {
        showLoader: false,
        isDisabled: false,
        installedApplicationList: [],
        profileId: this.$route.query.profileId,
        serialNumber: this.$route.query.serialNumber,
        playstoreText: this.$route.query.playstoreText,
        playStoreApplications: [],
        deviceDetail: null,
        ageFilter: this.$route.query.ageFilter,
        filterCategory:this.$route.query.filterCategory,
        expertRatingFilter: this.$route.query.expertRatingFilter,
        playStoreCategories: [],
        playstoreLabel:"No data found",
        lastUpdated: "No data found.",
        installationFailureReason: "",
        appInstallaionErrors : {"INSTALLATION_FAILURE_REASON_UNKNOWN":"An unknown condition is preventing the app from being installed. Some potential reasons are that the device doesn't have enough storage, the device network connection is unreliable, or the installation is taking longer than expected. The installation will be retried automatically.",
        "IN_PROGRESS":"The installation is still in progress.", "NOT_FOUND":"The app was not found in Play.", "NOT_COMPATIBLE_WITH_DEVICE":"The app is incompatible with the device,",
        "NOT_APPROVED":"The app has not been approved by the admin.","PERMISSIONS_NOT_ACCEPTED":"The app has new permissions that have not been accepted by the admin.",
        "NOT_AVAILABLE_IN_COUNTRY	":"The app is not available in the user's country.", "NO_LICENSES_REMAINING":"There are no licenses available to assign to the user.",
        "NOT_ENROLLED":"The enterprise is no longer enrolled with Managed Google Play or the admin has not accepted the latest Managed Google Play Terms of Service.",
        "USER_INVALID":"The user is no longer valid. The user may have been deleted or disabled.",
        "NETWORK_ERROR_UNRELIABLE_CONNECTION":"A network error on the user's device has prevented the install from succeeding. This usually happens when the device's internet connectivity is degraded, unavailable or there's a network configuration issue. Please ensure the device has access to full internet connectivity on a network that meets Android Enterprise Network Requirements. App install or update will automatically resume once this is the case.",
        "INSUFFICIENT_STORAGE":"The user's device does not have sufficient storage space to install the app. This can be resolved by clearing up storage space on the device. App install or update will automatically resume once the device has sufficient storage."},
        hiddenPackages: ['com.nova.kidsfirstphone','com.facebook.katana','com.twitter.android','com.instagram.android','com.linkedin.android','com.zhiliaoapp.musically','com.pinterest','com.snapchat.android','com.instagram.barcelona','com.google.android.youtube', 'com.facebook.lite', 'com.facebook.pages.app', 'com.facebook.work', 'com.instagram.android', 'com.facebook.adsmanager', 'com.facebook.workchat', 'com.facebook.orca', 'com.instagram.lite', 'org.telegram.messenger', 'com.discord', 'com.twitter.android']
    }
  },
  methods: {
    getDeviceProfileApplicationList() {
      let data = {
        profile_id: this.profileId,
        install_type: "FORCE_INSTALLED"
      }
      ProfileService.getDeviceProfileApplicationList(data)
        .then((response) => {
          this.installedApplicationList = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    async getPlayStore(currentFilter = null) {
      this.showLoader = true;
      $('#sortByPopup').collapse('hide');
      $('#filterByCategoryPopup').collapse('hide');
      $('#expertRatingPopup').collapse('hide');
        if (currentFilter == "Terms") {
          this.ageFilter = "";
          this.filterCategory = "";
          this.expertRatingFilter = "";
        } else if (currentFilter == "Age") {
          this.filterCategory = "";
          this.playstoreText = "";
          this.expertRatingFilter = "";
        } else if (currentFilter == "Category") {
          this.playstoreText = "";
          this.ageFilter = "";
          this.expertRatingFilter = "";
        }
        this.isDisabled = true;
        let data = {
            profileId: this.profileId,
            serialNumber: this.serialNumber,
            playstoreText: this.playstoreText,
            ageFilter: this.ageFilter,
            filterCategory: this.filterCategory,
            expertRatingFilter: this.expertRatingFilter
        }
        if(this.ageFilter) {
            if ($("#all").length > 0) {
              $("#all")[0].click();
            }
            if (this.ageFilter == "AGE_RANGE1") {
              this.playstoreLabel = "Show result for Ages up to 5";
            } else if (this.ageFilter == "AGE_RANGE2") {
              this.playstoreLabel = "Show result for Ages 6-8";
            } else if (this.ageFilter == "AGE_RANGE3") {
              this.playstoreLabel = "Show result for Ages 9-12";
            } else {
              this.playstoreLabel = "Show result for All ages up to 12";
            }
            this.filterCategory = "";
        } else if(this.filterCategory) {
            if ($("#all").length > 0) {
              $("#all")[0].click();
            }
            this.playstoreLabel = "Show result for " + this.filterCategory.replace(/_/g, ' ') + " Category";
        } else if (this.playstoreText) {
          this.playstoreLabel = "Show result for " + this.playstoreText;
          if ($("#all").length > 0) {
              $("#all")[0].click();
          }
        } else if (this.expertRatingFilter) {
          let lable = (this.expertRatingFilter == "EXPERT_RECOMMENDATION") ? "Expert Recommendation" : "Expert Recommendation With Parental Supervision"
          if (this.expertRatingFilter == "NOT_RATED") {
            lable = "Not Certified"
          }
          this.playstoreLabel = "Show result for " + lable;
          if ($("#all").length > 0) {
            $("#all")[0].click();
          }
        }  else {
          this.playstoreLabel = "No data found"
        } 
        
        this.playStoreApplications = [];
        await KidsService.getPlayStore(data)
            .then((response) => {
                this.showLoader = false;
                //this.filterCategory = "";
                this.playStoreApplications = [];
                this.isDisabled = false;
                this.playStoreApplications = response.data.data
                this.deviceDetail = response.data.deviceDetail;
                if (this.deviceDetail && this.deviceDetail.lastDeviceActivationDate) {
                    this.lastUpdated = new Date(this.deviceDetail.lastDeviceActivationDate).toLocaleDateString("en-US", options) + " " + new Date(this.deviceDetail.lastDeviceActivationDate).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
                } else {
                    this.lastUpdated = "No data found.";
                }
            })
            .catch((e) => {
                this.showLoader = false;
                this.playStoreApplications = [];
                this.isDisabled = false;
                this.$toast.error(e);
            });
    },
    closePopUp() {
      $('#sortByPopup').collapse('hide');
      $('#filterByCategoryPopup').collapse('hide');
      $('#expertRatingPopup').collapse('hide');
    },
    async clearExpertRatingFilter() {
      this.expertRatingFilter = "";
      await this.getPlayStore();
      $('#expertRatingPopup').collapse('hide');
    },
    async clearAgeFilter() {
      this.ageFilter = "";
      await this.getPlayStore();
      $('#sortByPopup').collapse('hide');
    },
    async clearCategoryFilter() {
      this.filterCategory = "";
      await this.getPlayStore();
      $('#filterByCategoryPopup').collapse('hide');
    },
    async createProfileApplication(app) {
        this.isDisabled = true;
        let masterApplicationData = {
            product_id: app.appId,
            package_name: app.appId,
            app_icon: app.icon,
            application_details: app,
            type: "Android"
        };
        await ProfileService.createMasterApplication(masterApplicationData)
        .then((response) => {
        }).catch((e) => {
        })

      let data = {
        profile_id: this.profileId,
        package_name: app.appId,
        is_web_app: false,
        type: "Android",
        serialNumber: this.serialNumber,
      };

      await ProfileService.createProfileApplication(data)
        .then((response) => {
            this.applyPolicy();
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
            this.getPlayStore();
            this.getDeviceProfileApplicationList();
        })
        .catch((e) => {
            this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    async uninstallProfileApplication(package_name) {
      this.isDisabled = true;
      var data = {
        package_name: package_name,
        profile_id: this.profileId,
        serialNumber: this.serialNumber
      };

      await ProfileService.uninstallProfileApplication(data)
        .then(async (response) => {
            await this.applyPolicy();
            this.deleteProfileApplication(package_name);
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    deleteProfileApplication(package_name) {
        this.isDisabled = true;
        var data = {
          package_name: package_name,
          profile_id: this.profileId,
          serialNumber: this.serialNumber
        };

        ProfileService.deleteProfileApplication(data)
        .then((response) => {
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
            this.getPlayStore();
            this.getDeviceProfileApplicationList();
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    async applyPolicy() {
      this.isDisabled = true;
      let data = {
        profile_id: this.profileId,
        serialNumber: this.serialNumber
      };

      await ProfileService.applyPolicy(data)
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.isDisabled = false;
          //this.$toast.error(e.response.data.respones_msg);
        });
    },
    openAppInstallationErrorPopup(reason) {
      $('#appInstallationFailurPopup').collapse('show');
      this.installationFailureReason = reason;
    },
    closeAppInstallationErrorPopup() {
      $('#appInstallationFailurPopup').collapse('hide');
      this.installationFailureReason = "";
    }
  },
  watch: {
  },
  beforeMount() {
    this.getPlayStore();
    this.getDeviceProfileApplicationList();

    let array = [
    'ANDROID_WEAR',       'APPLICATION',        'ART_AND_DESIGN',
    'AUTO_AND_VEHICLES',  'BEAUTY',             'BOOKS_AND_REFERENCE',
    'BUSINESS',           'COMICS',             'COMMUNICATION',
    'DATING',             'EDUCATION',          'ENTERTAINMENT',
    'EVENTS',             'FAMILY',             'FINANCE',
    'FOOD_AND_DRINK',     'GAME',               'GAME_ACTION',
    'GAME_ADVENTURE',     'GAME_ARCADE',        'GAME_BOARD',
    'GAME_CARD',          'GAME_CASINO',        'GAME_CASUAL',
    'GAME_EDUCATIONAL',   'GAME_MUSIC',         'GAME_PUZZLE',
    'GAME_RACING',        'GAME_ROLE_PLAYING',  'GAME_SIMULATION',
    'GAME_SPORTS',        'GAME_STRATEGY',      'GAME_TRIVIA',
    'GAME_WORD',          'HEALTH_AND_FITNESS', 'HOUSE_AND_HOME',
    'LIBRARIES_AND_DEMO', 'LIFESTYLE',          'MAPS_AND_NAVIGATION',
    'MEDICAL',            'MUSIC_AND_AUDIO',    'NEWS_AND_MAGAZINES',
    'PARENTING',          'PERSONALIZATION',    'PHOTOGRAPHY',
    'PRODUCTIVITY',       'SHOPPING',           'SOCIAL',
    'SPORTS',             'TOOLS',              'TRAVEL_AND_LOCAL',
    'VIDEO_PLAYERS',      'WATCH_FACE',         'WEATHER'
  ]
    const chunkSize = 3;
    let finalArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        finalArray.push(chunk)
        // do whatever
    }
    this.playStoreCategories = finalArray
  }, 
}
</script>