<template>
    <div>
      <KidHeader :style="{ 'max-width': '575px', 'margin': '0 auto', 'padding-left': '15px', 'padding-right': '15px' }" :profileId="profileId" :serialNumber="serialNumber" />
      <div class="container-fluid">
          <div class="main-container">
              <div class="profile-section mb-2">
                  <div class="pl-2">
                      <span>
                          <a v-if="!serialNumber" class="text-black" href="javascript:void(0)" @click="$router.push({ path: '/kids-device'})"><i class="fas fa-long-arrow-alt-left"></i>&nbsp; <b>Apps</b></a>
                          <a v-else class="text-black" href="javascript:void(0)" @click="$router.push({ path: '/play-store', query: { profileId: this.profileId, serialNumber: this.serialNumber, playstoreText: this.playstoreText, filterCategory: this.filterCategory, ageFilter: this.ageFilter, expertRatingFilter: this.expertRatingFilter }})"><i class="fas fa-long-arrow-alt-left"></i>&nbsp; <b>Apps</b></a>
                      </span>
                  </div>
              </div>
              <div class="profile-section mb-2" style="border-bottom: unset;">
                  <div class="nav nav-pills" role="tablist">
                      <a class="nav-link active" href="#info" id="infoTab" role="tab" data-toggle="tab">Info</a>
                      <a class="nav-link" href="#usage" id="usageTab" role="tab" data-toggle="tab">Usage</a>
                  </div>
                  <div class="tab-content p-1">
                      <div class="tab-pane active" id="info">
                          <div v-if="applicationDetail && applicationDetail.playStoreDetail">
                              <div class="w-100 text-center p-2 bg" :style="{ backgroundImage: 'url(' + applicationDetail.playStoreDetail.headerImage + ')', height: '200px', 'background-repeat': 'round' } ">
                              </div>
                              <div class="row border-bottom p-2 pl-3 text-center">
                                  <div class="col-xs-2">
                                      <img :src="applicationDetail.playStoreDetail.icon" style="height: 40px; width: 40px;">
                                  </div>&nbsp;
                                  <div class="col-xs-10 pt-2">
                                      <span>{{ applicationDetail.playStoreDetail.title }}</span>
                                      <span class="pl-4" v-if="applicationDetail.playStoreDetail.price > 0">₣ {{ applicationDetail.playStoreDetail.price }}</span>
                                  </div>
                              </div>
                              <div class="p-2 text-center ScrollStyle" style="display: flow-root;white-space:nowrap;border-bottom: 1px solid rgba(0, 0, 0, 0.1);">
                                  <div class="text-center" style="display: inline-grid;">
                                      <label :style="{ 'margin': 0 }">
                                          <img v-if="applicationDetail && applicationDetail.expertRating == 'EXPERT_RECOMMENDATION'" :src="exportGreen" height="17px" width="auto" />
                                          <img v-else-if="applicationDetail && applicationDetail.expertRating == 'PARENTAL_SUPERVISION'" :src="exportOrange" height="17px" width="auto" />
                                          <img v-else-if="applicationDetail && applicationDetail.expertRating == '16+'" :src="sixteenPlus" height="17px" width="auto" />
                                          <img v-else :src="notRated" height="17px" width="auto" />
                                      </label>
                                      <label class="text-muted" :style="{ 'padding-top': '7px' }">Expert Rating</label>
                                  </div>
                                  <div class="text-center" style="display: inline-grid;" v-if="applicationDetail.playStoreDetail.scoreText">
                                      <span style="height: 32px;"><i class="fas fa-star" style="color: gold;"></i> <b>{{ applicationDetail.playStoreDetail.scoreText }}</b></span>
                                      <label class="text-muted">&nbsp;&nbsp;Google Play</label>
                                  </div>
                                  <div class="pl-3 text-center" style="display: inline-grid;">
                                      <span style="height: 32px;">
                                        <!--<i class="far fa-smile" :class="{'rating-green': overallRating > 0,  'rating-grey': overallRating <= 0 }"></i>-->
                                        <img class="mb-1" v-if="kidsRatingDetail.avgKidsRating >= 3" :src="childPositiveSmile" />
                                        <img class="mb-1" v-else-if="kidsRatingDetail.avgKidsRating >= 2 && kidsRatingDetail.avgKidsRating < 3" :src="childNeutralSmile" />
                                        <img class="mb-1" v-else-if="kidsRatingDetail.avgKidsRating >= 1 && kidsRatingDetail.avgKidsRating < 2" :src="childNegativeSmile" />
                                        <i v-else class="far fa-smile rating-grey" />
                                        <b>&nbsp;{{ overallRating }}</b></span>
                                      <label class="text-muted">Parents & Kids</label>
                                  </div>
                                  <div class="pl-3 text-center" style="display: inline-grid;" v-if="applicationDetail.playStoreDetail.contentRating">
                                      <span style="height: 32px;"><b>{{ applicationDetail.playStoreDetail.contentRating }}</b></span>
                                      <label class="text-muted">Age Group</label>
                                  </div>
                                  <div class="pl-3 text-center" style="display: inline-grid;">
                                      <span style="height: 32px;"><b>{{ applicationDetail.playStoreDetail.genreId.replace(/_/g, ' ').toLowerCase() }}</b></span>
                                      <label class="text-muted">Category</label>
                                  </div>
                                  <!--<div class="pl-3 text-center" style="display: inline-grid;">
                                      <span style="height: 32px;">4+</span>
                                      <label>Age</label>
                                  </div>-->
                              </div>
                              <div class="collapse mt-2 mr-2 footer-full" id="reviewPopup">
                                <div class="card height-100">
                                    <div class="card-body">
                                        <h5 class="text-black pb-2">
                                            <b>Your App Rating</b>
                                            <span class="float-right"><a class="text-black" href="javascript:void(0)" @click="closeRatingPopup()"><i class="fas fa-times"></i></a></span>
                                        </h5>
                                        <div class="ScrollStyle" v-if="applicationDetail.appExist == true">
                                          <div class="profile-section mb-1" style="border-bottom: unset;">
                                              <div class="card w-100 background-dark">
                                                  <div class="card-body">
                                                      <img v-if="applicationDetail.playStoreDetail" :src="applicationDetail.playStoreDetail.icon" style="height: 40px; width: 40px;">
                                                      <img v-else :src="applicationDetail.app_icon" style="height: 40px; width: 40px;">
                                                      <span class="pl-2">
                                                        <b v-if="applicationDetail.playStoreDetail">{{ applicationDetail.playStoreDetail.title }}</b>
                                                        <b v-else>{{ applicationDetail.package_name }}</b>
                                                      </span>
                                                  </div>
                                                </div>
                                            </div>
                                            <div>
                                              <label><b>Was it educational for your child?</b></label>
                                              <star-rating v-model:rating="isEducational" :show-rating="false" :star-size="30" />
                                            </div><hr class="rating-hr">  
                                            <div>
                                              <label>
                                                <b>Do you feel it is helping its creativity?</b>
                                              </label>
                                              <star-rating v-model:rating="isPositive" :show-rating="false" :star-size="30" />
                                            </div><hr class="rating-hr">
                                            <div>
                                              <label>
                                                <b>How suitable was the content for your child’s age?</b>
                                                <!--<br><small>Was the app easy for your child to navigate and understand?</small>-->
                                              </label>
                                              <star-rating v-model:rating="isSuitableContent" :show-rating="false" :star-size="30" />
                                            </div><hr class="rating-hr">
                                            <div>
                                              <label>
                                                <b>Was the app easy for your child to navigate and understand?</b>
                                              </label>
                                              <star-rating v-model:rating="isEasyToUnderstand" :show-rating="false" :star-size="30" />
                                            </div><hr class="rating-hr">
                                            <div>
                                              <label><b>Would you recommend it to other parents with kids in the same age group?</b></label>
                                              <div> 
                                                <button type="button" :style="{ 'border-color':'#d2d8dd' }" class="btn btn-light mr-2" :class="{ 'focus': isRecommend == false }" @click="updateIsRecommend(false)">👎 No</button>
                                                <button type="button" :style="{ 'border-color':'#d2d8dd' }" class="btn btn-light" :class="{ 'focus': isRecommend == true }" @click="updateIsRecommend(true)">👍 Yes</button>
                                              </div>
                                            </div><hr class="rating-hr">
                                            <div>
                                              <label><b>Help other parents with your comments:</b></label>
                                              <div class="form-group">
                                                <textarea class="form-control" v-model="review" rows="1"></textarea>
                                              </div>
                                            </div>
                                            <div>
                                                <button type="button" class="btn btn-primary w-100" :disabled="isDisabled" :style="{ 'border-radius': '20px' }" @click="submitParentRating()">
                                                  Submit<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                              <div class="p-2 text-center" style="border-bottom: 1px solid rgba(0, 0, 0, 0.1);">
                                  <button class="btn btn-primary rounded" v-if="applicationDetail.playStoreDetail.free == false && applicationDetail.isAppInstalled == false" >Coming Soon</button>
                                  <div v-else>
                                    <button type="button" v-if="applicationDetail.isAppInstalled == false" class="btn btn-primary rounded" style="width: 90%;" @click="createProfileApplication(applicationDetail.playStoreDetail)">Install</button>
                                    <button type="button" v-else class="btn btn-danger btn-danger-customized rounded" style="width: 50%;" @click="uninstallProfileApplication(this.package_name)" :disabled="isDisabled"><i class="fas fa-trash-alt text-danger-customized"></i>&nbsp; <b class="text-danger-customized">Uninstall</b></button>
                                  </div>
                                  <div>
                                    <span class="text-muted" :style="{ 'font-size': '12px' }" v-if="applicationDetail && applicationDetail.playStoreDetail.adSupported == true">
                                      Contains Ads
                                    </span>
                                    <span class="text-muted" :style=  "{ 'font-size': '12px' }" v-if="applicationDetail && applicationDetail.playStoreDetail.offersIAP == true">
                                      &nbsp;&nbsp;In-App Purchase
                                    </span>
                                  </div>
                                  
                              </div>
                              <div class="pt-2 text-center">
                                  <div class="accordion" id="accordionExample">
                                      <div class="card border-bottom">
                                        <div class="card-header custom-header" id="headingOne">
                                          <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left text-black" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                              <b>App Images</b>
                                            </button>
                                          </h2>
                                        </div>
                                    
                                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne">
                                          <div class="card-body" style="display: flex;overflow: auto;">
                                            <img v-for="screenshot in applicationDetail.playStoreDetail.screenshots" :key="screenshot" class="p-1" :src="screenshot" style="height: 200px; width: auto;" />
                                          </div>
                                        </div>
                                      </div>
                                      <div class="card border-bottom pt-2">
                                        <div class="card-header custom-header" id="headingTwo">
                                          <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left collapsed text-black" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                              <b>Description </b>
                                              <span class="float-right">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect x="24" y="24" width="24" height="24" rx="12" transform="rotate(-180 24 24)" fill="#F2F2F2"/>
                                                  <path d="M15.3337 10.3333L12.1574 13.5996C12.0707 13.6889 11.93 13.6889 11.8433 13.5996L8.66699 10.3333" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                              </span>
                                            </button>
                                          </h2>
                                        </div>
                                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" >
                                          <div class="card-body">
                                              <p class="text-left" v-html="applicationDetail.playStoreDetail.descriptionHTML"></p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="card border-bottom pt-2">
                                        <div class="card-header custom-header" id="headingThree">
                                          <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left text-black collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                              <b>Certificate</b> 
                                              <span class="float-right">
                                                <img v-if="applicationDetail && applicationDetail.expertRating == 'EXPERT_RECOMMENDATION'" :src="exportGreen" height="17px" width="auto" />
                                                <img v-else-if="applicationDetail && applicationDetail.expertRating == 'PARENTAL_SUPERVISION'" :src="exportOrange" height="17px" width="auto" />
                                                <img v-else-if="applicationDetail && applicationDetail.expertRating == '16+'" :src="sixteenPlus" height="17px" width="auto" />
                                                <img v-else :src="notRated" height="17px" width="auto" />
                                                <!--<h2 class="badge badge-light"><i class="fas fa-crown"></i> Silver</h2>-->&nbsp;
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect x="24" y="24" width="24" height="24" rx="12" transform="rotate(-180 24 24)" fill="#F2F2F2"/>
                                                  <path d="M15.3337 10.3333L12.1574 13.5996C12.0707 13.6889 11.93 13.6889 11.8433 13.5996L8.66699 10.3333" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                              </span>
                                            </button>
                                          </h2>
                                        </div>
                                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree">
                                          <div class="card-body">
                                            <p class="text-left" v-if="applicationDetail && applicationDetail.expertRatingComment" v-html="applicationDetail.expertRatingComment"></p>
                                            <p class="text-left" v-else>This app has not been certified yet by our expert team.</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="card border-bottom pt-2">
                                          <div class="card-header custom-header" id="headingThree">
                                            <h2 class="mb-0">
                                              <button class="btn btn-link btn-block text-left text-black collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                                                <b>Play Store Rating</b> 
                                                <span class="float-right">
                                                  <span class="pl-4"><i class="fas fa-star" style="color: gold;"></i> {{ applicationDetail.playStoreDetail.scoreText }} &nbsp;</span> 
                                                  <span class="pl-1" v-if="applicationDetail.playStoreDetail.reviews"><i class="far fa-comment-dots"></i> {{ formatNumber(applicationDetail.playStoreDetail.reviews) }}</span>
                                                  &nbsp;<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="24" y="24" width="24" height="24" rx="12" transform="rotate(-180 24 24)" fill="#F2F2F2"/>
                                                    <path d="M15.3337 10.3333L12.1574 13.5996C12.0707 13.6889 11.93 13.6889 11.8433 13.5996L8.66699 10.3333" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                  </svg>
                                                </span>
                                              </button>
                                            </h2>
                                          </div>
                                          <div id="collapseFour" class="collapse" aria-labelledby="headingThree" >
                                            <div class="card-body">
                                              <p class="text-left">Please go to Playstore to view all reviews: 
                                                <a :href="applicationDetail.playStoreDetail.url" target="_blank">{{ applicationDetail.playStoreDetail.title }}</a>
                                              </p>
                                            </div>
                                          </div>
                                      </div>
                                      <div class="card border-bottom pt-2">
                                          <div class="card-header custom-header" id="headingThree">
                                            <h2 class="mb-0">
                                              <button class="btn btn-link btn-block text-left text-black collapsed" type="button" data-toggle="collapse" data-target="#parentsKidsRating" aria-expanded="false" >
                                                <b>Parents & Kids Rating</b> 
                                                <span class="float-right">
                                                  <label>
                                                    <img class="mb-1" v-if="kidsRatingDetail.avgKidsRating >= 3" :src="childPositiveSmile" />
                                                    <img class="mb-1" v-else-if="kidsRatingDetail.avgKidsRating >= 2 && kidsRatingDetail.avgKidsRating < 3" :src="childNeutralSmile" />
                                                    <img class="mb-1" v-else-if="kidsRatingDetail.avgKidsRating >= 1 && kidsRatingDetail.avgKidsRating < 2" :src="childNegativeSmile" />
                                                    <i v-else class="far fa-smile rating-grey" />
                                                    {{ overallRating }} &nbsp;
                                                  </label> 
                                                  &nbsp;<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="24" y="24" width="24" height="24" rx="12" transform="rotate(-180 24 24)" fill="#F2F2F2"/>
                                                    <path d="M15.3337 10.3333L12.1574 13.5996C12.0707 13.6889 11.93 13.6889 11.8433 13.5996L8.66699 10.3333" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                  </svg>
                                                </span>
                                              </button>
                                            </h2>
                                          </div>
                                          <div id="parentsKidsRating" class="collapse" >
                                            <div class="card-body text-left">
                                              <b>Help other parents</b>
                                              <button type="button" class="install-app-btn float-right"  data-toggle="collapse" data-target="#reviewPopup" aria-controls="reviewPopup" aria-expanded="false" aria-label="Toggle navigation"><b>Rate This App</b></button>  
                                              
                                              <div class="parent-rating-section" v-if="parentsRatingDetail">
                                                <div class="pt-4">
                                                  <p><b>Parents</b> <span class="float-right"><b>{{ parentsRatingDetail.parentsRatingAvg }}</b> ({{ parentsRatingDetail.totalParentsRating }} Ratings)</span></p>
                                                </div>
                                                <div>
                                                    <p>
                                                      Educational 
                                                      <span class="float-right" :style="{ 'width': '100px', 'padding-top': '8px' }">
                                                        <div class="progress">
                                                          <div class="progress-bar" :style="{ 'width': parentsRatingDetail.educationalRatingPercentage + '%' }" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                            <label>{{ parentsRatingDetail.educationalRatingAvg }}</label>
                                                          </div>
                                                        </div>
                                                      </span>
                                                    </p>
                                                    <p>
                                                      Supports Creativity
                                                      <span class="float-right" :style="{ 'width': '100px', 'padding-top': '8px' }">
                                                        <div class="progress">
                                                          <div class="progress-bar" :style="{ 'width': parentsRatingDetail.positiveRatingPercentage + '%' }" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                            <label>{{ parentsRatingDetail.positiveRatingAvg }}</label>
                                                          </div>
                                                        </div>
                                                      </span>
                                                    </p>
                                                    <p>
                                                      Suitable Content
                                                      <span class="float-right" :style="{ 'width': '100px', 'padding-top': '8px' }">
                                                        <div class="progress">
                                                          <div class="progress-bar" :style="{ 'width': parentsRatingDetail.suitableRatingPercentage + '%' }" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                            <label>{{ parentsRatingDetail.suitableContentRatingAvg }}</label>
                                                          </div>
                                                        </div>
                                                      </span>
                                                    </p>
                                                    <p>
                                                      Easy to Understand
                                                      <span class="float-right" :style="{ 'width': '100px', 'padding-top': '8px' }">
                                                        <div class="progress">
                                                          <div class="progress-bar" :style="{ 'width': parentsRatingDetail.easyToUnderstaRatingPercentage + '%' }" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                            <label>{{ parentsRatingDetail.easyToUnderstandAvg }}</label>
                                                          </div>
                                                        </div>
                                                      </span>
                                                    </p>
                                                </div>
                                                <div class="ScrollStyle" style="display: -webkit-box;">
                                                  <div class="card mr-3 mb-2" style="width: 11rem; border-bottom: 1px solid rgba(0,0,0,.125)" v-for="review in parentsRatingDetail.lastFiveReviews" :key="review._id">
                                                    <div class="card-body">
                                                      <h5 class="card-title">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M6.75 6C6.75 7.39239 7.30312 8.72774 8.28769 9.71231C9.27226 10.6969 10.6076 11.25 12 11.25C13.3924 11.25 14.7277 10.6969 15.7123 9.71231C16.6969 8.72774 17.25 7.39239 17.25 6C17.25 4.60761 16.6969 3.27226 15.7123 2.28769C14.7277 1.30312 13.3924 0.75 12 0.75C10.6076 0.75 9.27226 1.30312 8.28769 2.28769C7.30312 3.27226 6.75 4.60761 6.75 6Z" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                          <path d="M2.25 23.25C2.25 20.6641 3.27723 18.1842 5.10571 16.3557C6.93419 14.5272 9.41414 13.5 12 13.5C14.5859 13.5 17.0658 14.5272 18.8943 16.3557C20.7228 18.1842 21.75 20.6641 21.75 23.25" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>

                                                        <span class="float-right">
                                                          <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M10.2273 13.3154C10.1444 12.1698 10.2273 12.1701 9.5625 10.8974C11.5568 8.9883 14.6788 9.89452 15.1966 10.2399C15.7144 10.5853 16.1374 11.0453 16.4296 11.5807C16.7218 12.1162 16.8746 12.7113 16.8749 13.3154" fill="#CAECFF"/>
                                                          <path d="M2.78223 4.9155C2.78223 5.65812 3.0904 6.37031 3.63895 6.89542C4.1875 7.42053 4.93149 7.71553 5.70725 7.71553C6.48302 7.71553 7.22701 7.42053 7.77556 6.89542C8.32411 6.37031 8.63228 5.65812 8.63228 4.9155C8.63228 4.17289 8.32411 3.46069 7.77556 2.93559C7.22701 2.41048 6.48302 2.11548 5.70725 2.11548C4.93149 2.11548 4.1875 2.41048 3.63895 2.93559C3.0904 3.46069 2.78223 4.17289 2.78223 4.9155Z" fill="#CAECFF" stroke="#69A9CD" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                                          <path d="M0.920898 13.3155C0.920898 12.1003 1.42518 10.9349 2.3228 10.0756C3.22043 9.21637 4.43787 8.73364 5.70731 8.73364C6.97674 8.73364 8.19418 9.21637 9.09181 10.0756C9.98943 10.9349 10.4937 12.1003 10.4937 13.3155" fill="#CAECFF"/>
                                                          <path d="M0.920898 13.3155C0.920898 12.1003 1.42518 10.9349 2.3228 10.0756C3.22043 9.21637 4.43787 8.73364 5.70731 8.73364C6.97674 8.73364 8.19418 9.21637 9.09181 10.0756C9.98943 10.9349 10.4937 12.1003 10.4937 13.3155H0.920898Z" stroke="#69A9CD" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                                          <path d="M10.5654 6.44279C10.5654 7.05038 10.8176 7.63308 11.2664 8.06272C11.7152 8.49235 12.3239 8.73372 12.9586 8.73372C13.5933 8.73372 14.2021 8.49235 14.6509 8.06272C15.0997 7.63308 15.3518 7.05038 15.3518 6.44279C15.3518 5.83519 15.0997 5.25249 14.6509 4.82285C14.2021 4.39322 13.5933 4.15186 12.9586 4.15186C12.3239 4.15186 11.7152 4.39322 11.2664 4.82285C10.8176 5.25249 10.5654 5.83519 10.5654 6.44279Z" fill="#CAECFF" stroke="#69A9CD" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                                          <path d="M10.8923 13.3156H16.8754C16.8751 12.7115 16.7223 12.1164 16.4301 11.5809C16.1379 11.0454 15.7149 10.5854 15.1971 10.2401C14.6793 9.8947 14.082 9.67416 13.4561 9.59721C12.8301 9.52025 12.194 9.58917 11.6019 9.79809C10.6879 10.1924 10.2275 10.8973 10.2275 10.8973" stroke="#69A9CD" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                                          </svg>  
                                                          <small class="text-black pt-1">&nbsp;<b>{{ (review.isEducational + review.isPositive + review.isSuitableContent + review.isEasyToUnderstand) / 4 }}</b></small>
                                                        </span>
                                                      </h5>
                                                      <p class="card-text" v-if="review.name">{{ review.name }}</p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div>
                                                  <b>Recommends it to friends:</b> 
                                                  <div>
                                                    <a href="javascript:void(0)"><i class="fas fa-thumbs-up" :style="{ 'color': 'gold' }"></i></a> {{ applicationDetail.upVoting }}&nbsp;&nbsp;&nbsp;
                                                    <a href="javascript:void(0)"><i class="fas fa-thumbs-down" :style="{ 'color': 'gold' }"></i></a> {{ applicationDetail.downVoting }}
                                                  </div>
                                                </div>
                                              </div><hr>
                                              <div class="child-rating-section">
                                                <div class="pt-4">
                                                  <p><b>Kids</b> 
                                                    <span class="float-right" :style="{ 'line-height': 'normal' }">
                                                      <label v-if="kidsRatingDetail.avgKidsRating >= 3">
                                                          <img class="mb-1" :src="childPositiveSmile" />
                                                          <b> Positive</b>
                                                        </label> 
                                                        <label v-else-if="kidsRatingDetail.avgKidsRating >= 2 && kidsRatingDetail.avgKidsRating < 3">
                                                          <img class="mb-1" :src="childNeutralSmile" />
                                                          <b> Neutral</b>
                                                        </label>
                                                        <label v-else-if="kidsRatingDetail.avgKidsRating >= 1 && kidsRatingDetail.avgKidsRating < 2">
                                                          <img class="mb-1" :src="childNegativeSmile" />
                                                          <b> Negative</b>
                                                        </label>
                                                        <i v-else class="far fa-smile rating-grey" />
                                                        &nbsp;({{ kidsRatingDetail.totalKidsRating }} Ratings)
                                                    </span>
                                                  </p>
                                                </div>
                                                <div>
                                                    <p>
                                                      <img :src="childPositiveSmile" /><small>&nbsp;Positive</small> 
                                                      <span class="float-right" :style="{ 'width': '100px', 'padding-top': '8px' }">
                                                        <div class="progress">
                                                          <div class="progress-bar" role="progressbar" :style="{ 'width': kidsRatingDetail.positiveRatingPercentage + '%', 'background-color': '#00C52B' }" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                            <label>{{ kidsRatingDetail.positiveRatingCount }}</label>
                                                          </div>
                                                        </div>
                                                      </span>
                                                    </p>
                                                    <p>
                                                        <img :src="childNeutralSmile" /><small>&nbsp;Neutral</small> 
                                                      <span class="float-right" :style="{ 'width': '100px', 'padding-top': '8px' }">
                                                        <div class="progress">
                                                          <div class="progress-bar" :style="{ 'width': kidsRatingDetail.naturalRatingPercentage + '%', 'background-color': '#737373' }" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                            <label>{{ kidsRatingDetail.naturalRatingCount }}</label>
                                                          </div>
                                                        </div>
                                                      </span>
                                                    </p>
                                                    <p>
                                                      <img :src="childNegativeSmile" /><small>&nbsp;Negative</small> 
                                                      <span class="float-right" :style="{ 'width': '100px', 'padding-top': '8px' }">
                                                        <div class="progress">
                                                          <div class="progress-bar" :style="{ 'width': kidsRatingDetail.negativeRatingPercentage + '%', 'background-color': '#ED0040' }" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                            <label>{{ kidsRatingDetail.negativeRatingCount }}</label>
                                                          </div>
                                                        </div>
                                                      </span>
                                                    </p>
                                                </div>
                                                <div class="ScrollStyle" style="display: -webkit-box;">
                                                  <div class="card mr-3 mb-2" style="width: 11rem; border-bottom: 1px solid rgba(0,0,0,.125)" v-for="review in kidsRatingDetail.lastFiveReviews" :key="review._id">
                                                    <div class="card-body">
                                                      <h5 class="card-title">
                                                        <img :src="kidAvatar" width="30px" height="auto" />
                                                        <span class="float-right">
                                                          <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M10.2273 13.3154C10.1444 12.1698 10.2273 12.1701 9.5625 10.8974C11.5568 8.9883 14.6788 9.89452 15.1966 10.2399C15.7144 10.5853 16.1374 11.0453 16.4296 11.5807C16.7218 12.1162 16.8746 12.7113 16.8749 13.3154" fill="#CAECFF"/>
                                                          <path d="M2.78223 4.9155C2.78223 5.65812 3.0904 6.37031 3.63895 6.89542C4.1875 7.42053 4.93149 7.71553 5.70725 7.71553C6.48302 7.71553 7.22701 7.42053 7.77556 6.89542C8.32411 6.37031 8.63228 5.65812 8.63228 4.9155C8.63228 4.17289 8.32411 3.46069 7.77556 2.93559C7.22701 2.41048 6.48302 2.11548 5.70725 2.11548C4.93149 2.11548 4.1875 2.41048 3.63895 2.93559C3.0904 3.46069 2.78223 4.17289 2.78223 4.9155Z" fill="#CAECFF" stroke="#69A9CD" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                                          <path d="M0.920898 13.3155C0.920898 12.1003 1.42518 10.9349 2.3228 10.0756C3.22043 9.21637 4.43787 8.73364 5.70731 8.73364C6.97674 8.73364 8.19418 9.21637 9.09181 10.0756C9.98943 10.9349 10.4937 12.1003 10.4937 13.3155" fill="#CAECFF"/>
                                                          <path d="M0.920898 13.3155C0.920898 12.1003 1.42518 10.9349 2.3228 10.0756C3.22043 9.21637 4.43787 8.73364 5.70731 8.73364C6.97674 8.73364 8.19418 9.21637 9.09181 10.0756C9.98943 10.9349 10.4937 12.1003 10.4937 13.3155H0.920898Z" stroke="#69A9CD" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                                          <path d="M10.5654 6.44279C10.5654 7.05038 10.8176 7.63308 11.2664 8.06272C11.7152 8.49235 12.3239 8.73372 12.9586 8.73372C13.5933 8.73372 14.2021 8.49235 14.6509 8.06272C15.0997 7.63308 15.3518 7.05038 15.3518 6.44279C15.3518 5.83519 15.0997 5.25249 14.6509 4.82285C14.2021 4.39322 13.5933 4.15186 12.9586 4.15186C12.3239 4.15186 11.7152 4.39322 11.2664 4.82285C10.8176 5.25249 10.5654 5.83519 10.5654 6.44279Z" fill="#CAECFF" stroke="#69A9CD" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                                          <path d="M10.8923 13.3156H16.8754C16.8751 12.7115 16.7223 12.1164 16.4301 11.5809C16.1379 11.0454 15.7149 10.5854 15.1971 10.2401C14.6793 9.8947 14.082 9.67416 13.4561 9.59721C12.8301 9.52025 12.194 9.58917 11.6019 9.79809C10.6879 10.1924 10.2275 10.8973 10.2275 10.8973" stroke="#69A9CD" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                                                          </svg>  
                                                          <small class="text-black pt-1">&nbsp;<b>{{ review.kidsRating }}</b></small>
                                                        </span>
                                                      </h5>
                                                      <small class="card-text">{{ review.review }}</small>
                                                      <p class="card-text">{{ HumanReadableDateFormat(review.created_at, true) }}</p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div>
                                                  <b>Recommends it to friends:</b> 
                                                  <div>
                                                    <a href="javascript:void(0)"><i class="fas fa-thumbs-up" :style="{ 'color': 'gold' }"></i></a> {{ kidsRatingDetail.upVoting }}&nbsp;&nbsp;&nbsp;
                                                    <a href="javascript:void(0)"><i class="fas fa-thumbs-down" :style="{ 'color': 'gold' }"></i></a> {{ kidsRatingDetail.downVoting }}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                      </div>
                                      <div class="card border-bottom pt-2">
                                        <div class="card-header custom-header" id="headingThree">
                                          <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left text-black collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseThree">
                                              <b>What's New</b> 
                                              <span class="float-right">
                                                <span class="pl-4" v-if="applicationDetail.playStoreDetail">{{ timeSince(applicationDetail.playStoreDetail.updated) }}</span>
                                                &nbsp;<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="24" y="24" width="24" height="24" rx="12" transform="rotate(-180 24 24)" fill="#F2F2F2"/>
                                                    <path d="M15.3337 10.3333L12.1574 13.5996C12.0707 13.6889 11.93 13.6889 11.8433 13.5996L8.66699 10.3333" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                  </svg>
                                              </span>
                                            </button>
                                          </h2>
                                        </div>
                                        <div id="collapseFive" class="collapse" aria-labelledby="headingThree">
                                          <div class="card-body">
                                            <p class="text-left" v-html="applicationDetail.playStoreDetail.recentChanges"></p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="card border-bottom pt-2">
                                        <div class="card-header custom-header" id="headingThree">
                                          <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left text-black collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseThree">
                                              <b>App Privacy</b> 
                                              <span class="float-right">
                                                &nbsp;<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="24" y="24" width="24" height="24" rx="12" transform="rotate(-180 24 24)" fill="#F2F2F2"/>
                                                    <path d="M15.3337 10.3333L12.1574 13.5996C12.0707 13.6889 11.93 13.6889 11.8433 13.5996L8.66699 10.3333" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                  </svg>
                                              </span>
                                            </button>
                                          </h2>
                                        </div>
                                        <div id="collapseSix" class="collapse" aria-labelledby="headingThree" >
                                          <div class="card-body">
                                            <p class="text-left"><a :href="applicationDetail.playStoreDetail.privacyPolicy">{{ applicationDetail.playStoreDetail.privacyPolicy }}</a></p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="card border-bottom pt-2">
                                        <div class="card-header custom-header" id="headingThree">
                                          <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left text-black collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseThree">
                                              <b>Information</b>
                                              <span class="float-right">
                                                &nbsp;<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="24" y="24" width="24" height="24" rx="12" transform="rotate(-180 24 24)" fill="#F2F2F2"/>
                                                    <path d="M15.3337 10.3333L12.1574 13.5996C12.0707 13.6889 11.93 13.6889 11.8433 13.5996L8.66699 10.3333" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                  </svg>
                                              </span> 
                                            </button>
                                          </h2>
                                        </div>
                                        <div id="collapseSeven" class="collapse" aria-labelledby="headingThree" >
                                          <div class="card-body" v-if="applicationDetail.playStoreDetail">
                                            <p class="text-left text-muted">Provider <span class="float-right text-black">{{ applicationDetail.playStoreDetail.developer }}</span></p>
                                            <p class="text-left text-muted">Category <span class="float-right text-black">{{ applicationDetail.playStoreDetail.genre }}</span></p>
                                            <p class="text-left text-muted">Compatibility <span class="float-right text-black">{{ applicationDetail.playStoreDetail.androidVersionText }}</span></p>
                                            <p class="text-left text-muted">Age Rating <span class="float-right text-black">{{ applicationDetail.playStoreDetail.contentRating }}</span></p>
                                            <p class="text-left text-muted">In-App Purchase 
                                              <span class="float-right text-black" v-if="applicationDetail.playStoreDetail.offersIAP == true">Yes</span>
                                              <span class="float-right text-black" v-else>No</span>
                                            </p>
                                            <p class="text-left text-muted">Copyright <span class="float-right text-black">{{ applicationDetail.playStoreDetail.developerId }}</span></p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                              </div>
                          </div>
                          <span v-if="applicationDetail && !applicationDetail.playStoreDetail" class="text-danger">Unable to fetch application detail from play store.</span>                            
                      </div>
                      <div class="tab-pane" id="usage" v-if="applicationDetail">
                          <div v-if="applicationDetail.appExist == true">
                              <div class="profile-section mb-2" style="border-bottom: unset;">
                                <div class="card w-100 background-dark">
                                    <!--<div class="card-body">
                                        <img v-if="applicationDetail.playStoreDetail" :src="applicationDetail.playStoreDetail.icon" style="height: 40px; width: 40px;">
                                        <img v-else :src="applicationDetail.app_icon" style="height: 40px; width: 40px;">
                                        <span class="pl-2">
                                          <b v-if="applicationDetail.playStoreDetail">{{ applicationDetail.playStoreDetail.title }}</b>
                                          <b v-else>{{ applicationDetail.package_name }}</b>
                                        </span>
                                        <span class="float-right pt-2">
                                            <small v-if="isAppEnabled == true">Enabled &nbsp;</small> 
                                            <small v-else>Disabled &nbsp;</small> 
                                            <label class="switch">
                                                <input type="checkbox" v-model="isAppEnabled" @change="toggleAppStatus()">
                                                <span class="slider round"></span>
                                            </label>
                                        </span>
                                    </div>-->
                                    <div class="card-body">
                                        <div class="p-2 d-flex align-items-center">
                                            <div class="dropdown-list-image mr-2">
                                                <img v-if="applicationDetail.playStoreDetail" :src="applicationDetail.playStoreDetail.icon" style="height: 40px; width: 40px;">
                                                <img v-else :src="applicationDetail.app_icon" style="height: 40px; width: 40px;">
                                            </div>
                                            <div class="font-weight-bold">
                                                <div class="mb-2">
                                                  <b v-if="applicationDetail.playStoreDetail">{{ applicationDetail.playStoreDetail.title }}</b>
                                                  <b v-else>{{ applicationDetail.package_name }}</b>
                                                </div>
                                            </div>
                                            <span class="ml-auto mb-auto">
                                                <div class="text-right text-muted pt-1">
                                                  <small v-if="isAppEnabled == true">Enabled &nbsp;</small> 
                                                  <small v-else>Disabled &nbsp;</small> 
                                                  <label class="switch">
                                                      <input type="checkbox" v-model="isAppEnabled" @change="toggleAppStatus()">
                                                      <span class="slider round"></span>
                                                  </label>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                  </div>
                              </div>
                              <div class="profile-section" :style="{'border-bottom': 'unset'}">
                                  <p><b>Daily Limit</b> - <small class="text-black">In Minute</small></p>
                                  <div class="card w-100 background-dark">
                                      <div class="card-body" :style="{ 'padding': '3px', 'padding-top': '8px', 'padding-bottom': 'unset' }">
                                          <span class="pl-1" :style="{ 'font-size': '15px' }">Enabled Daily Limit</span>
                                          <span class="float-right" :style="{ 'padding-top': '2px' }">
                                              <label class="switch">
                                                  <input type="checkbox" v-model="enableDailyLimit">
                                                  <span class="slider round"></span>
                                              </label>
                                          </span>
                                      </div>
                                  </div>
                                  <div class="pt-2 pb-4">
                                      <label>Set a daily limit
                                        <a href="javascript:void(0)" @click="openDailyLimitPopup()" class="text-black"><i class="fas fa-info-circle"></i></a>
                                      </label>
                                      <div class="float-right" :style="{'width': '50%' }">
                                          <div class="input-group" :style="{ 'width': '95%', 'padding-right': '1px', 'border': '1px solid #dee2e6' }">
                                              <div class="input-group-prepend">
                                                  <div class="input-group-text" :style="{ 'background-color': 'white', 'border': 'unset' }" id="btnGroupAddon">
                                                      <a class="text-black" href="javascript:void(0)" @click="decreaseLimit()">
                                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <rect y="24" width="24" height="24" rx="12" transform="rotate(-90 0 24)" fill="#F2F2F2"/>
                                                              <path d="M13.6667 15.3333L10.4003 12.157C10.311 12.0703 10.311 11.9296 10.4003 11.8429L13.6667 8.66663" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                          </svg>
                                                      </a>
                                                  </div>
                                              </div>
                                              <input type="text" class="form-control text-black text-center" :style="{ 'border': 'unset', 'font-weight': 'bold' }" :readonly="enableDailyLimit == false" :class="{ 'text-warning': isDeliveredToDevice == false, 'text-success': isDeliveredToDevice == true }" v-model="dailyLimitTime" @blur="roundOF15()" >
                                              <div class="input-group-prepend">
                                                  <div class="input-group-text" :style="{ 'background-color': 'white', 'border': 'unset' }" id="btnGroupAddon">
                                                      <a class="text-black" href="javascript:void(0)" @click="increaseLimit()">
                                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <rect x="24" width="24" height="24" rx="12" transform="rotate(90 24 0)" fill="#F2F2F2"/>
                                                              <path d="M10.3333 8.66671L13.5997 11.843C13.689 11.9297 13.689 12.0704 13.5997 12.1571L10.3333 15.3334" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                          </svg>
                                                      </a>
                                                  </div>
                                              </div>
                                          </div>
                                          <!--<input type="number" class="form-control" :readonly="enableDailyLimit == false" :class="{ 'text-warning': isDeliveredToDevice == false, 'text-success': isDeliveredToDevice == true }" v-model="dailyLimitTime" step=15 />
                                          <small :class="{ 'text-warning': isDeliveredToDevice == false, 'text-success': isDeliveredToDevice == true }">In Minute.</small>-->
                                      </div>
                                  </div>
                              </div>
                              <div class="profile-section text-center" >
                                  <button type="button" class="btn btn-primary" :style=" { 'background': '#0073EE', 'border-radius': '20px', 'width': '30%' }" @click="updateDailyLimit()">Save</button>
                              </div>
                              <div class="profile-section" :style="{ 'border': 'unset'}">
                                <p class="p-2"><b>Activity</b></p>
                                <div class="btn-group btn-group-justified nav nav-pills justify-content-center" role="tablist" >
                                  <a class="btn btn-light nav-link w-50 active" id="day" :style="{ 'text-decoration': 'none', 'padding': '4px', 'background-color': '#F7F7F7' }" href="javascript:void(0)" role="tab" data-toggle="tab" @click="changeFilterType('Day')">Day</a>
                                  <a class="btn btn-light nav-link w-50" id="week" :style="{ 'text-decoration': 'none', 'padding': '4px', 'background-color': '#F7F7F7' }" href="javascript:void(0)" role="tab" data-toggle="tab" @click="changeFilterType('Week')">Week</a>
                                </div>
                                <div class="pt-3" :style="{ 'padding-right': '2px'}">
                                  <div class="input-group border">
                                          <div class="input-group-prepend">
                                              <div class="input-group-text" :style="{ 'background-color': 'white', 'border-left': 'unset','border-top': 'unset', 'border-bottom': 'unset' }" id="btnGroupAddon">
                                                  <a class="text-black" href="javascript:void(0)" @click="previousDate()">
                                                      <!--<i class="fas fa-chevron-circle-left"></i>-->
                                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                          <rect y="24" width="24" height="24" rx="12" transform="rotate(-90 0 24)" fill="#F2F2F2"/>
                                                          <path d="M13.6667 15.3333L10.4003 12.157C10.311 12.0703 10.311 11.9296 10.4003 11.8429L13.6667 8.66663" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                      </svg>

                                                  </a>
                                              </div>
                                          </div>
                                          <div class="form-control text-center" :style="{ 'border': 'unset', 'font-weight': 'bold' }">{{ this.formattedDate }}</div>
                                          <div class="input-group-prepend">
                                              <div class="input-group-text" :style="{ 'background-color': 'white', 'border-left': 'unset','border-top': 'unset', 'border-bottom': 'unset' }" id="btnGroupAddon">
                                                  <a class="text-black" href="javascript:void(0)" @click="nextDate()">
                                                      <!--<i class="fas fa-chevron-circle-right"></i>-->
                                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                          <rect x="24" width="24" height="24" rx="12" transform="rotate(90 24 0)" fill="#F2F2F2"/>
                                                          <path d="M10.3333 8.66671L13.5997 11.843C13.689 11.9297 13.689 12.0704 13.5997 12.1571L10.3333 15.3334" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                      </svg>

                                                  </a>
                                              </div>
                                          </div>
                                      </div>
                              </div>
                              </div>
                              <div class="profile-section" style="border-bottom: unset;">
                                  <div class="card" :style="{ 'border': 'unset' }">
                                      <div class="card-body" :style="{ padding: 'unset' }">
                                        <h6 class="text-black">
                                            <b>Screen Time: </b><span class="text-primary"><b>{{ totalUsedTimeInFormat }}</b> </span>
                                        </h6> 
                                          <CanvasJSChart :options="options" :styles="styleOptions"/>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <span v-else class="text-danger">Please install app first</span>
                      </div>
                  </div>
              </div>
              <div class="profile-section text-center">
                    Last Update: {{ lastUpdated }}
              </div>
              <div class="profile-section mb-2" >
                    <div class="collapse footer" id="dailyLimitPopup">
                        <div class="card">
                            <div class="card-body">
                                <h6 class="text-black">
                                    <b>Daily Limit</b> <a href="javascript:void(0)" class="text-black" @click="closeDailyLimitPopup()"><i class="fas fa-times float-right pt-1 pr-2"></i></a>
                                </h6><hr>
                                <p class="text-black">
                                  <ul>
                                    <li><b>Orange:</b> Time limit sent to device</li>
                                    <li><b>Green:</b> Time limit successfully applied to device</li>
                                  </ul>
                                </p>
                            </div>
                        </div>
                    </div>
              </div>
              <KidFooter />
          </div>
      </div>
    </div>
</template>
<script>
import ProfileService from "../../services/ProfileService";
import KidsService from "../../services/KidsService";
import StarRating from 'vue-star-rating'
import KidHeader from '@/components/KidHeader.vue'
import KidFooter from '@/components/KidFooter.vue'
let options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };

export default {
  name: 'AppDetail',
  components: {
    KidHeader,
    KidFooter,
    StarRating
  },
  props: {
    notRated: {
        type: String,
        default: "./images/not-certified.png",
    },
    exportOrange: {
        type: String,
        default: "./images/experts-orange.png",
    },
    exportGreen: {
        type: String,
        default: "./images/experts-green.png",
    },
    sixteenPlus: {
        type: String,
        default: "./images/16-plus.png",
    },
    childPositiveSmile: {
        type: String,
        default: "./images/child-positive-smile.png",
    },
    childNeutralSmile:{
        type: String,
        default: "./images/child-neutral-smile.png",
    },
    childNegativeSmile:{
        type: String,
        default: "./images/child-negative-smile.png",
    },
    kidAvatar: {
      type: String,
      default: "./images/kids-avatar.png",
    }
  },
  data() {
    return {
      isDisabled: false,
      package_name: this.$route.query.package_name,
      profileId: this.$route.query.profile_id,
      serialNumber: this.$route.query.serialNumber,
      playstoreText: this.$route.query.playstoreText,
      filterCategory: this.$route.query.filterCategory,
      expertRatingFilter: this.$route.query.expertRatingFilter,
      ageFilter: this.$route.query.ageFilter,
      applicationDetail: null,
      enableDailyLimit: false,
      dailyLimitTime: 0,
      isAppEnabled: false,
      currentDate: new Date(),
      formattedDate: new Date().toLocaleDateString("en-US", options),
      options: null,
      styleOptions: {
            width: "100%",
           
      },
      lastUpdated: "No data found.",
      deviceDetail: null,
      filterType: "Day",
      weekType: "Current",
      isEducational: 0,
      isPositive: 0,
      isSuitableContent: 0,
      isEasyToUnderstand: 0,
      review: "",
      isRecommend: null,
      isEasyToStop: null,
      isDeliveredToDevice: false,

      kidsRatingDetail: {},
      parentsRatingDetail: {},
      overallRating: 0,
      totalUsedTime: 0,
      totalUsedTimeInFormat: "",
    }
  },
  methods: {
    async getApplicationDetail() {
      let data = {
        profile_id: this.profileId,
        package_name: this.package_name,
        currentDate: this.currentDate,
        filterType: this.filterType,
        weekType: this.weekType
      }
      await ProfileService.getApplicationDetail(data)
        .then((response) => {
          this.applicationDetail = response.data.data;
          this.kidsRatingDetail = response.data.kidsRating;
          this.overallRating = response.data.overallRating;
          this.parentsRatingDetail = response.data.parentRating;
          this.totalUsedTime = response.data.totalUsedTime;
          this.enableDailyLimit = this.applicationDetail.enableDailyLimit;
          this.dailyLimitTime = (this.applicationDetail.dailyLimitTime) ? this.applicationDetail.dailyLimitTime : 0;
          this.isDeliveredToDevice = this.applicationDetail.isDeliveredToDevice;
          if (this.applicationDetail.appExist == true) {
            this.isAppEnabled = (this.applicationDetail.is_app_disabled == true) ? false : true;
          }
        }).catch((e) => {
          this.$toast.error(e);
        });
    },
    updateDailyLimit() {
      if (this.enableDailyLimit == true && ! this.dailyLimitTime) {
        return this.$toast.error("Please enter time.");  
      }
      if (this.dailyLimitTime % 15 != 0 ) {
        return this.$toast.error("Please enter time multiple of 15.");   
      } 
      let data = {
        profile_id: this.profileId,
        package_name: this.package_name,
        dailyLimitTime: this.dailyLimitTime,
        enableDailyLimit: this.enableDailyLimit,
        serialNumber: this.serialNumber,
      }
      ProfileService.updateDailyLimit(data)
        .then((response) => {
          this.$toast.success(response.data.respones_msg);
          this.isDeliveredToDevice = false;
          this.applyPolicy();
        }).catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    async createProfileApplication(app) {
        this.isDisabled = true;
        let masterApplicationData = {
            product_id: app.appId,
            package_name: app.appId,
            app_icon: app.icon,
            application_details: app,
            type: "Android",
        };
        await ProfileService.createMasterApplication(masterApplicationData)
        .then((response) => {
        }).catch((e) => {
        })

      let data = {
        profile_id: this.profileId,
        package_name: app.appId,
        is_web_app: false,
        type: "Android",
        serialNumber: this.serialNumber,
      };

      await ProfileService.createProfileApplication(data)
        .then((response) => {
            this.applyPolicy();
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
            this.getApplicationDetail();
        })
        .catch((e) => {
            this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    async uninstallProfileApplication(package_name) {
      this.isDisabled = true;
      var data = {
        package_name: package_name,
        profile_id: this.profileId,
        serialNumber: this.serialNumber
      };

      await ProfileService.uninstallProfileApplication(data)
        .then(async (response) => {
            await this.applyPolicy();
            this.deleteProfileApplication(package_name);
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    deleteProfileApplication(package_name) {
        if (package_name == "com.nova.kidsfirstphone") {
          this.$toast.error("You don't have permission to uninstall this app");
          return;
        } 
        this.isDisabled = true;
        var data = {
          package_name: package_name,
          profile_id: this.profileId,
        };

        ProfileService.deleteProfileApplication(data)
          .then((response) => {
              this.applyPolicy();
              this.isDisabled = false;
              this.$toast.success(response.data.respones_msg);
              this.getApplicationDetail();
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
    },

    async applyPolicy() {
      this.isDisabled = true;
      let data = {
        profile_id: this.profileId,
        serialNumber: this.serialNumber
      };

      await ProfileService.applyPolicy(data)
        .then((response) => {
          this.isDisabled = false;
        })
        .catch((e) => {
          this.isDisabled = false;
          //this.$toast.error(e.response.data.respones_msg);
        });
    },

    async getAppUsageChart() {
        let data = {
        profileId: this.profileId,
        serialNumber: this.serialNumber,
        packageName: this.package_name,
        currentDate: this.currentDate,
        filterType: this.filterType,
        weekType: this.weekType
      }
      await KidsService.getAppUsageChart(data)
        .then((response) => {
            this.options = response.data.data
            this.deviceDetail = response.data.deviceDetail;
            if (this.deviceDetail && this.deviceDetail.lastDeviceActivationDate) {
                this.lastUpdated = new Date(this.deviceDetail.lastDeviceActivationDate).toLocaleDateString("en-US", options) + " " + new Date(this.deviceDetail.lastDeviceActivationDate).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
            } else {
                this.lastUpdated = "No data found.";
            }
            this.totalUsedTimeInFormat = response.data.totalUsedTimeInFormat;
        }).catch((e) => {
          this.$toast.error(e);
        });
    },
    changeFilterType(type) {
        this.filterType = type;
        if (this.filterType == "Day") {
            this.formattedDate = this.currentDate.toLocaleDateString("en-US", options);
        } else {
            this.formattedDate = "This Week";
            this.weekType = "Current";
        }
    },
    previousDate() {
      if (this.filterType == "Day") {
        let day = this.currentDate.getTime() - (1 * 24 * 60 * 60 * 1000);
        this.currentDate.setTime(day);
        this.formattedDate = this.currentDate.toLocaleDateString("en-US", options);
      } else {
          this.formattedDate = "Previous Week"
          this.weekType = "Previous";
      }
      this.getAppUsageChart();
    },
    nextDate() {
      if (this.filterType == "Day") {
        let day = this.currentDate.getTime() + (1 * 24 * 60 * 60 * 1000);
        this.currentDate.setTime(day);
        this.formattedDate = this.currentDate.toLocaleDateString("en-US", options)
      } else {
        this.formattedDate = "This Week",
        this.weekType = "Current";    
      }  
      this.getAppUsageChart();
    },
    closeRatingPopup() {
        $('#reviewPopup').collapse('hide');
    },
    updateIsEasyToStop(value) {
      this.isEasyToStop = value;
    },
    updateIsRecommend(value) {
      this.isRecommend = value;
    },
    submitParentRating() {
      this.isDisabled = true;
      let data = {
        packageName: this.package_name,
        isEducational: this.isEducational,
        isPositive: this.isPositive,
        isEasyToStop: this.isEasyToStop,
        isSuitableContent:this.isSuitableContent,
        isEasyToUnderstand: this.isEasyToUnderstand,
        isRecommend: this.isRecommend,
        review: this.review,
        profileId: this.profileId
      };

      KidsService.submitParentRating(data)
        .then((response) => {
          this.isDisabled = false;
          this.getApplicationDetail();
          $('#reviewPopup').collapse('hide');
          this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    updateAppRecommendations(isRecommended) {
      this.isDisabled = true;
      let data = {
        packageName: this.package_name,
        isRecommend: isRecommended,
      };

      KidsService.updateAppRecommendations(data)
        .then((response) => {
          this.isDisabled = false;
          this.getApplicationDetail();
          this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    toggleAppStatus() {
      if (this.enableDailyLimit == true && this.totalUsedTime >= this.dailyLimitTime) {
          this.isAppEnabled = false;
          return this.$toast.error("Please increase daily limit time to enable this category.");  
      }
      var data = {
          application_id: this.applicationDetail._id,
          is_app_disabled: (this.isAppEnabled == true) ? false : true,
          serialNumber: this.serialNumber  
        };

        ProfileService.toggleProfileAppStatus(data)
          .then((response) => {
              this.applyPolicy();
              this.isDisabled = false;
              this.$toast.success(response.data.respones_msg);
              this.getApplicationDetail();
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
    },

    timeSince(previous) {
      var msPerMinute = 60 * 1000;
      var msPerHour = msPerMinute * 60;
      var msPerDay = msPerHour * 24;
      var msPerMonth = msPerDay * 30;
      var msPerYear = msPerDay * 365;
      let current = Date.now();
      var elapsed = current - previous;

      if (elapsed < msPerMinute) {
          return Math.round(elapsed/1000) + ' seconds ago';   
      }

      else if (elapsed < msPerHour) {
          return Math.round(elapsed/msPerMinute) + ' minutes ago';   
      }

      else if (elapsed < msPerDay ) {
          return Math.round(elapsed/msPerHour ) + ' hours ago';   
      }

      else if (elapsed < msPerMonth) {
          return Math.round(elapsed/msPerDay) + ' days ago';   
      }

      else if (elapsed < msPerYear) {
          return Math.round(elapsed/msPerMonth) + ' months ago';   
      }

      else {
          return Math.round(elapsed/msPerYear ) + ' years ago';   
      }

    },
    formatNumber(number) {
      const formatCash = Intl.NumberFormat('en-US', {
        notation: "compact",
        maximumFractionDigits: 1
      }).format(number);
      return formatCash;
    },
    decreaseLimit() {
        if (this.dailyLimitTime > 0) {
            this.dailyLimitTime = this.dailyLimitTime - 15
        }
    },
    increaseLimit() {
        this.dailyLimitTime = (parseInt(this.dailyLimitTime) + 15)
    },
    openDailyLimitPopup() {
        $('#dailyLimitPopup').collapse('show')
    }, 
    closeDailyLimitPopup(){
        $('#dailyLimitPopup').collapse('hide')
    },
    roundOF15() {
        this.dailyLimitTime = Math.ceil(this.dailyLimitTime/15.0) * 15;
    },
    HumanReadableDateFormat(date, onlyDate = false) {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
        "July", "Aug", "Sept", "Oct", "Nov", "Dec"
        ];
        let dt = new Date(date);
        if (dt == "Invalid Date") {
            return "-";
        }
        let day = ("0" + dt.getDate()).slice(-2);
        let month = dt.getMonth();
        let year = dt.getFullYear();
        let hour = ("0" + dt.getHours()).slice(-2);
        let minute = ("0" + dt.getMinutes()).slice(-2);
        let seconds = ("0" + dt.getSeconds()).slice(-2);
        if (onlyDate) {
            return month + "/" + year;
        } else {
            return day + " " + monthNames[month] + " " + year + " " + hour + ":" + minute + ":" + seconds;
        }
    }
  },
  watch: {
    enableDailyLimit() {
        if (this.enableDailyLimit == false) {
          this.dailyLimitTime = 0
        } else {
          this.dailyLimitTime = (this.applicationDetail.dailyLimitTime) ? this.applicationDetail.dailyLimitTime : 15;
        }
    },
    dailyLimitTime() {
        if (this.dailyLimitTime < 0) {
            this.dailyLimitTime = 0;
        }
    },
    filterType() {
        this.getAppUsageChart();
    },
  },
  beforeMount() {
    this.getApplicationDetail();
    this.getAppUsageChart();
    let showRating = this.$route.query.showRating;
    if (showRating == 'true') {
      setTimeout(function() {
          $('#reviewPopup').collapse('show');
      }, 2500); 
    }
  }, 
  mounted: function () {
    this.$nextTick(() => {
      const that = this;
      $("#usageTab").on("click", function() {
          $("#week")[0].click();
          setTimeout(function(){
              $("#day")[0].click();
          }, 400); 
      });
    })
  }
}
</script>